import React, { useState } from "react";
import Button from "./Button";
import Logo_1 from "../assets/Logo_1.png";

const Nav = () => {
  const Links = [
    { name: "Product", link: "/products" },
    { name: "About Us", link: "/aboutus" },
    { name: "Contact Us", link: "/contact" },
    { name: "Privacy Policies", link: "/privacy" },
  ];

  const [open, setOpen] = useState(false);

  return (
    <div className="shadow-md w-full top-0 left-0 relative">
      <div className="md:flex items-center justify-between py-4 md:px-10 px-7">
        <div className="cursor-pointer w-32 ml-8">
          <a href="/"><img src={Logo_1} alt="Logo" /></a>
        </div>

        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
        >
          <ion-icon name={open ? "close" : "menu"}></ion-icon>
        </div>

        <ul
          className={`md:flex md:text-center md:items-center absolute md:static bg-white md:z-auto z-20 mt-[-8px] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-20 " : "top-[-490px]"
          }`}
        >
          {Links.map((link) => (
            <li
              key={link.name}
              className="md:ml-8 md:my-0 my-7 relative"
            >
              <a
                href={link.link}
                className="text-gray-800 hover:text-gray-400 duration-500"
              >
                {link.name}
              </a>
              
            </li>
          ))}
          <div className="md:hidden bg-white w-full py-10 ml-[-20px]">
            <Button>Explore Service</Button>
          </div>
        </ul>
        <div className="hidden md:block">
          <Button>Explore Service</Button>
        </div>
      </div>
    </div>
  );
};

export default Nav;

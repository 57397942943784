import React from "react";
// import AboutPic from "../assets/images/AboutPic.png";
import Footer from "../components/Footer";
import Nav from "../components/Nav";


const DataDeletion = () => {
  return <div>
    <Nav />

    <section>
          <div className=" mb-8 md:grid grid-cols-1 md:max-w-5xl m-auto mt-20">
            <div className="text-center md:text-start">
              <span className="font-semibold text-xl bg-pale-blue text-white p-4 py-2 rounded-full ">
               DD
              </span>
              <p className="font-semibold text-2xl mt-5">Data Deletion Instruction</p>

              <p className="text-black text-balance mt-5 text-2xl">
                   According to the Facebook Platform rules, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL. If you want to delete your activities for techcohort, follow these instructions:<br/><br/>


                   1 .Go to Your Facebook Account’s Setting &amp; Privacy. Click “Setting”.<br/>
                   2. Then, go to “Apps and Websites” and you will see all of your Apps activities.<br/>
                   3. Select the option box for techcohort
                   4. Click “Remove” button.<br/><br/>

                   If you wish to delete your user account data, you can do that throughout the application. Simply navigate to the “Profile” and then press the “More” button. You’ll find the option to “Delete account” there. Once you confirm this action, your personal data will be permanently deleted, and no information will be retained. <br/>
                   If you are unable to login, you can send us a request to support@techcohort.com . <br/> In the email body, provide the email address used for registration and we will take care of the rest.
              </p>

              
              
            </div>
             {/* <div className="md:ml-[-60px] md:mt-0 mt-14  ">
              <img src={AboutPic} alt="people working on a laptop" />
            </div> */}
          </div>
        </section>

        <Footer />
  </div>;
};

export default DataDeletion;

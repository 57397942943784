import React from "react";
// import AboutPic from "../assets/images/AboutPic.png";
import Footer from "../components/Footer";
import Nav from "../components/Nav";


const Privacy = () => {
  return <div>
    <Nav />

    <section>
          <div className=" mb-8 md:grid grid-cols-1 md:max-w-5xl m-auto mt-20">
            <div className="text-center md:text-start">
              <span className="font-semibold text-xl bg-pale-blue text-white p-4 py-2 rounded-full ">
                P
              </span>
              <p className="font-semibold text-2xl mt-5">PRIVACY POLICY</p>

              <p className="text-black text-balance mt-5 text-sm">
              Your privacy is important to us. techcohort Company (techcohort, “We”, “Our”, “Us”), is an online audience creating company, and we are sensitive to concerns about our use of personal information. We also believe in, and support, harnessing the power of technology and the Internet to aid communications, relationships, and discourse.<br/>

                By using techcohort Service (the “Service”) and techcohort Website( the “Site”) linking to this privacy policy (the “Privacy Policy”) as portrayed on the Terms of Service and end user license agreement, you are agreeing to comply with, and be bound by, the following Privacy Policy, and other supplemental terms and conditions or documents that may be published from time to time. Please review the Privacy Policy carefully.<br/><br/>

                WEBSITE PRIVACY POLICY<br/><br/>

                When you visit our Site, submit any information to us or click a weblink in an email you have received from us, we may use cookies to gather information about your interaction with our site and our other digital marketing materials. We may use the information you provide us to improve our products and services and your website experience.<br/><br/>

                TYPES OF INFROMATION OUR SERVICE COLLECTS<br/><br/>

                When you are using The Service, we may automatically collect certain information from your device. Service may collect, and why we collect it, follows: <br/>
                (a) your username, email address and contact preferences. This lets us create your account, provide you with convenient and personalized access to your account and deliver and support the Service. This information also helps us communicate with you and respond to your requests for assistance (including providing account verification support if you’re having difficultly accessing your account). In addition, we may use your Personal Information to send you direct marketing emails and special offers.<br/>
                 (b)Your payment information required to complete a commercial transaction on the Site. We use this information to enable and fulfill your transaction.
                 (c)Any User Content you add to your account. <br/>
                 (d)We don’t use this information for any purpose other than to provide the Services. <br/>
                 (e)The Service also collect and receive information from your computer or mobile device, including the activities you perform within your account, the type of hardware and software you are using (for example, your operating system, word processing or other productivity software and browser type) and information obtained from cookies. When you access the Service, that application will request access to certain information on your computing device.<br/>
                 (f)In addition, we automatically gather non-personally identifiable information that indicates your association with one of our business partners or certain promotions. We use this information to determine the amount of revenue share payments to make to partners with whom we might have such arrangements and to provide anonymized aggregate reports.<br/>
                 (g)If you choose to use PayPal® to finalize and pay for your order, you will provide your credit card number, directly to PayPal®. The privacy policy of PayPal® will apply to the information you provide on the PayPal® website.<br/><br/>

                SECURITY <br/><br/>

                The security of your information is important to us. We follow generally accepted industry standards to protect the solicited information submitted to us, both during transmission and once we receive it. However, no method of electronic transmission or method of electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security, and therefore you transmit information to us at your own risk. Once we receive your transmission, we make reasonable efforts to ensure its security on our systems. Access to Personal information is strictly limited, and not accessible to the public. All Personal Information of our users is restricted and used only in accordance with this Privacy Policy.<br/><br/>

                PROPER USE<br/><br/>

                You agree that you will use the Service in compliance with all applicable local, state, national, and international laws, rules and regulations, including any laws regarding the transmission of technical data exported from your country of residence. techcohort reserves the right, but shall have no obligation, to investigate your use of the Service in order to determine whether a violation of the End User Agreement has occurred or to comply with any applicable law, regulation, legal process or governmental request.<br/><br/>

                UNSOLICITED MATERIALS<br/><br/>

                Any unsolicited materials submitted or sent to techcohort, will be deemed to be not confidential or secret. By submitting or sending information or other material to techcohort you: <br/>
                (a) Warrant that you have all rights of any kind to the material and that to the best of your knowledge no other party has any rights to the material;<br/>
                 (b) Unless stated specifically otherwise therein, grant LeadEnforce an unrestricted, perpetual, irrevocable license to use, reproduce, display, perform, modify, transmit and distribute the material, and you further agree that LeadEnforce is free to use any ideas, know-how, concepts or techniques you send us for any purpose, without any compensation to you or any other person. In order to remove all doubt, techcohort privacy related obligation of this Privacy Policy shall not apply towards unsolicited information.<br/><br/>

                HOW WE USE INFORMATION<br/><br/>

                Any information that you submit to us remains your property. However, we may use it for the purposes described herein.<br/><br/>

                (I) Service. We reserve the right to use the information we collect, in order to administer, operate, and continuously improve the Service. We may also use the information to monitor and analyze trends, usage and activities in connection with the Service. Provided, however, that no Personal Information shall be disclosed to third parties within the scope of this clause, other than techcohort’s trusted affiliates which are responsible for supporting or enhancing the Services, and for such purposes only.<br/>

                (II) Advertisements. We reserve the right to use information we collect to create, market and distribute anonymized audience segments to third parties. We or our trusted affiliates may customize personalized content and advertisements and connect certain demographic or other data about you (received from third parties) to data we have collected from you, such as your mobile device identifier or IP address.<br/>

                (III) Aggregate Information. We compile aggregated information about our users to better optimize our Service, preferences, and to assist in our marketing efforts. We may also share aggregated data about our user base including the number of users that we have, certain trends, statistical data, etc., without disclosing any information.<br/>

                (IV) Legal Disclaimer. Notwithstanding anything to the contrary, we reserve the right to share any information (a) as required by law and/or to comply with a judicial proceeding, court order or legal process served on Site;<br/> (b) when we believe that disclosure is necessary to protect our rights;<br/> (c) if we believe that you are in violation of the Terms of Service of this Privacy Policy or any other of our operating rules, code of conduct, policies and other supplemental terms and conditions or documents that may be made available by us from time to time.<br/><br/>

                LINKS TO OTHER SERVICES AND WEBSITES<br/><br/>

                The Service, as well as any advertisement or other monetization means, may contain services, products, and/or links to other services, products, and websites, that are not owned or controlled by us. Please be aware that we are not responsible for the content nor for the privacy practices of these other websites, products, and services. We encourage you to be aware when you access a third party’s website or service and to read the privacy statements of each and every website and service you visit.<br/><br/>

                BUSINESS TRANSITIONS<br/><br/>

                If we go through a business transition such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information will likely be among the assets transferred.<br/><br/>

                CHILDREN UNDER 13 YEARS OF AGE<br/><br/>

                The services of techcohort are not intended for persons under 13 years of age. techcohort Website or products and services are not developed or directed at children. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide personal information through the Site or Service without their permission.<br/><br/>

                CHANGES TO OUR POLICY OR PRIVACY PRACTICES<br/><br/>

                We may revise this Policy or our privacy practices at any time. Any changes and/or updates to our Policy will be posted directly on this page. We encourage you to visit this page periodically to review our current Policy so that you will always know what information we gather and how we may use that information.

                CONTACT US<br/><br/>

                If you have a question regarding our Privacy Policy or you feel that your privacy was not treated in accordance with our Privacy Policy, or if you believe that your privacy has been compromised by any person in the course of using the Services, please contact techcohort at: support@techcohort.com and our privacy officer shall promptly investigate.<br/><br/>
              </p>

              
              
            </div>
             {/* <div className="md:ml-[-60px] md:mt-0 mt-14  ">
              <img src={AboutPic} alt="people working on a laptop" />
            </div> */}
          </div>
        </section>

        <Footer />
  </div>;
};

export default Privacy;

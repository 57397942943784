import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

//Pages
import Landing from "./pages/Landing";
import Products from "./pages/Products";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact/Contact";
import Privacy from "./pages/Privacy";
import DataDeletion from "./pages/Datadeletect";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Landing />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/products" element={<Products />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/data-deletion-instruction" element={<DataDeletion />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
